import React, { useEffect, useState } from "react";
import MapGP from "./MapGP";
import "../../styles/GraphPage/DeviceGP.css";
import { DateFromServInterface } from "../../LogicComp/GPFakeData";
import SortButtonDev from "../buttons/SortButtonDev";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ChartOptions,
  ChartData, // Import ChartData from 'chart.js'
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { usePremium } from "../../LogicComp/DataProvider";

// Registering necessary Chart.js components and plugin for data labels
ChartJS.register(Title, Tooltip, Legend, ArcElement, ChartDataLabels);

interface AddresGpInt {
  Dates: DateFromServInterface[];
}

interface DualData {
  country: string;
  clicks: number;
}

const DevicesGp = ({ Dates }: AddresGpInt) => {
  const [data, setData] = useState<DualData[]>([]);
  const [Device, setDevice] = useState<DualData[]>([]);
  const [OC, setOC] = useState<DualData[]>([]);
  const [Browser, setBrowser] = useState<DualData[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sortOption, setSortOption] = useState(0);
  const [flag, setFlag] = useState(false); // Flag for toggling view
  const { isPremium } = usePremium();
  const [isPro] = useState(
    isPremium !== "free"
  );

  const categories = [
    { name: "Устройство", data: Device },
    { name: "Браузер", data: Browser },
    { name: "ОС", data: OC },
  ];

  useEffect(() => {
    const device: DualData[] = [];
    const oc: DualData[] = [];
    const browser: DualData[] = [];

    Dates.forEach((value) => {
      let deviceFlag = false;
      device.forEach((d) => {
        if (d.country === value.device) {
          d.clicks++;
          deviceFlag = true;
        }
      });
      if (!deviceFlag) {
        device.push({ country: value.device, clicks: 1 });
      }

      let ocFlag = false;
      oc.forEach((o) => {
        if (o.country === value.os) {
          o.clicks++;
          ocFlag = true;
        }
      });
      if (!ocFlag) {
        oc.push({ country: value.os, clicks: 1 });
      }

      let browserFlag = false;
      browser.forEach((b) => {
        if (b.country === value.browser) {
          b.clicks++;
          browserFlag = true;
        }
      });
      if (!browserFlag) {
        browser.push({ country: value.browser, clicks: 1 });
      }
    });

    setDevice(device);
    setOC(oc);
    setBrowser(browser);
    setData(device);
  }, [Dates]);

  useEffect(() => {
    setData(categories[currentIndex].data);
  }, [currentIndex, categories]);

  useEffect(() => {
    let sortedData = [...categories[currentIndex].data];
    switch (sortOption) {
      case 0:
        sortedData = [...categories[currentIndex].data];
        break;
      case 1:
        sortedData.sort((a, b) => a.country.localeCompare(b.country));
        break;
      case 2:
        sortedData.sort((a, b) => b.country.localeCompare(a.country));
        break;
      case 3:
        sortedData.sort((a, b) => b.clicks - a.clicks);
        break;
      case 4:
        sortedData.sort((a, b) => a.clicks - b.clicks);
        break;
      default:
        break;
    }
    setData(sortedData);
  }, [sortOption, data]);

  // const handlePrev = () => {
  //   setCurrentIndex((prevIndex) =>
  //     prevIndex === 0 ? categories.length - 1 : prevIndex - 1
  //   );
  // };

  // const handleNext = () => {
  //   setCurrentIndex((prevIndex) =>
  //     prevIndex === categories.length - 1 ? 0 : prevIndex + 1
  //   );
  // };

  const columns = [
    { label: "Алфавит ↓", value: 1 },
    { label: "Алфавит ↑", value: 2 },
    { label: "По кликам ↓", value: 3 },
    { label: "По кликам ↑", value: 4 },
  ];

  // Processing data for pie chart
  const processPieData = (data: DualData[]) => {
    const totalClicks = data.reduce((sum, item) => sum + item.clicks, 0);
    const minDegree = 15;
    const minClicks = (minDegree / 360) * totalClicks;

    const maxOtherClicks = (30 / 360) * totalClicks;

    const sortedData = [...data].sort((a, b) => b.clicks - a.clicks);

    const topData = sortedData.filter((item) => item.clicks >= minClicks);
    const otherData = sortedData.filter((item) => item.clicks < minClicks);

    if (otherData.length > 0) {
      const otherClicks = otherData.reduce((sum, item) => sum + item.clicks, 0);
      const limitedOtherClicks = Math.min(otherClicks, maxOtherClicks);

      topData.push({ country: "Другое", clicks: limitedOtherClicks });

      if (otherClicks > maxOtherClicks) {
        const excessClicks = otherClicks - maxOtherClicks;
        const remainingSegments = topData.length - 1;

        topData.forEach((item, index) => {
          if (item.country !== "Другое") {
            item.clicks += excessClicks / remainingSegments;
          }
        });
      }
    }

    return topData;
  };

  // Data for pie chart
  const pieData: ChartData<"pie"> = {
    labels: processPieData(data).map((d) => d.country),
    datasets: [
      {
        data: processPieData(data).map((d) => d.clicks),
        backgroundColor: [
          "#4285F4", // Google Blue
          "#DB4437", // Google Red
          "#F4B400", // Google Yellow
          "#0F9D58", // Google Green
          "#AB47BC", // Google Purple
          "#00ACC1", // Google Cyan
          "#FF5733", // Example additional color 1
          "#FFC300", // Example additional color 2
          "#DAF7A6", // Example additional color 3
          "#FF8C00", // Example additional color 4
          "#E67E22", // Example additional color 5
          "#2ECC71", // Example additional color 6
        ],
      },
    ],
  };

  // Pie chart options
  const options: ChartOptions<"pie"> = {
    plugins: {
      tooltip: {
        enabled: false, // Disable tooltips
      },
      legend: {
        display: true, // Enable legend
        position: "bottom", // Position legend below the chart
        labels: {
          boxWidth: 10, // Width of the color box
          padding: 10, // Padding between legend items
          font: {
            size: 11, // Font size for legend labels
            weight: "bold", // Font weight for legend labels
          },
          color: "#333", // Color of the legend text
        },
      },
      datalabels: {
        color: "#000", // Text color for data labels
        formatter: (value: number) => `${value}`, // Display only the value inside the segment
        anchor: "center", // Anchor the labels inside the segment
        align: "center", // Align labels inside the segment
        offset: 0, // No offset; place the labels directly inside
        textAlign: "center",
        padding: {
          top: 0,
          bottom: 0,
        },
        font: {
          size: 12, // Adjust the font size as needed
        },
      },
    },
    layout: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0, // Add padding to the left
        right: 0, // Add padding to the right
      },
    },
    cutout: "40%", // Reduce the size of the outer pie by increasing the cutout
    animation: {
      animateRotate: true, // Enable rotation animation
      duration: 20, // Accelerate the animation to 20 ms
      animateScale: false, // Disable scale animation
    },
    responsive: true, // Ensure the chart is responsive to container size
    maintainAspectRatio: true, // Maintain aspect ratio
  };

  // Function to draw custom lines and text
  const drawCustomLinesAndText = (chart: any) => {
    const ctx = chart.ctx;
    chart.data.datasets[0].data.forEach((value: number, i: number) => {
      const meta = chart.getDatasetMeta(0);
      const { startAngle, endAngle } = meta.data[i].getProps(
        ["startAngle", "endAngle"],
        true
      );
      const middleAngle = startAngle + (endAngle - startAngle) / 2;
      const radius = meta.data[i].getProps(["outerRadius"], true);

      const x = chart.width / 2 + (radius + 20) * Math.cos(middleAngle);
      const y = chart.height / 2 + (radius + 20) * Math.sin(middleAngle);

      // Draw lines
      ctx.beginPath();
      ctx.moveTo(
        chart.width / 2 + radius * Math.cos(middleAngle),
        chart.height / 2 + radius * Math.sin(middleAngle)
      );
      ctx.lineTo(x, y);
      ctx.stroke();

      ctx.textAlign = middleAngle < Math.PI ? "left" : "right";
      const label = chart.data.labels ? chart.data.labels[i] : "Unknown"; // Handle potential undefined
      ctx.fillText(`${label}: ${value}`, x, y - 10);
    });
  };

  useEffect(() => {
    // Render the chart and add custom drawing after it's done
    const chart = ChartJS.getChart("pie-chart");
    if (chart) {
      if (chart.options.plugins) {
        // Safely update options.plugins if it is defined
        (chart.options.plugins.tooltip as { enabled?: boolean }).enabled =
          false; // Disable tooltips
        (chart.options.plugins.legend as { display?: boolean }).display = true; // Show legend
      }
      chart.render(); // Render chart

      // Add custom drawing after the chart has been drawn
      chart.canvas.addEventListener("afterDraw", () => {
        drawCustomLinesAndText(chart);
      });
    }
  }, [pieData, options]);

  return (
    <div className="AddressCountryDev">
      <div className="AddHeader">
        <div className="CategoryDev">
          {categories.map((category, index) => (
            <span
              key={index}
              className={`CategoryItem ${
                currentIndex === index ? "selected" : ""
              }`}
              onClick={() => setCurrentIndex(index)}
            >
              {category.name}
            </span>
          ))}
        </div>
        <div className="FontSizeTextGPDev">
          <SortButtonDev columns={columns} setSortOption={setSortOption} />
          <button
            className={`ToggleViewButton ${flag ? "active" : ""}`}
            onClick={() => setFlag(!flag)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={`ToggleViewIcon ${flag ? "active" : ""}`}
            >
              <path
                d="M21 10C21 6.13401 17.866 3 14 3V10H21Z"
                stroke={flag ? "#FFFFFF" : "#1C274C"}
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 21C15.4183 21 19 17.4183 19 13H11V5C6.58172 5 3 8.58172 3 13C3 17.4183 6.58172 21 11 21Z"
                stroke={flag ? "#FFFFFF" : "#1C274C"}
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* If user has Pro access, show the map or pie chart */}
      {isPro ? (
        <div
          style={{
            height: "300px",
            overflowY: "auto",
            overflowX: "hidden",
            marginTop: "25px",
            display: flag ? "flex" : "block",
            justifyContent: flag ? "center" : "initial",
            flexDirection: flag ? "column" : "initial",
            alignItems: flag ? "center" : "initial",
          }}
        >
          {flag ? (
            <Pie data={pieData} options={options} id="pie-chart" />
          ) : (
            data.map((value, index) => (
              <div key={index}>
                <MapGP
                  name={value.country}
                  clickCount={value.clicks}
                  SVG={"qwe"}
                  category={categories[currentIndex].name}
                  country_code={""}
                />
              </div>
            ))
          )}
        </div>
      ) : (
        /* If user is not Pro, show the gradient overlay and access message */
        <div className="blurred-container">
          <div className="blur-overlay"></div>
          <div className="access-icon">
          <svg
              version="1.1"
              id="Layer_1"
              x="0px"
              y="0px"
              viewBox="0 0 473.931 473.931"
              xmlSpace="preserve"
            >
              <circle
                style={{ fill: "#E84849" }}
                cx="236.966"
                cy="236.966"
                r="236.966"
              />
              <path
                style={{ fill: "#F4F5F5" }}
                d="M429.595,245.83c0,16.797-13.624,30.417-30.417,30.417H74.73c-16.797,0-30.421-13.62-30.421-30.417 v-17.743c0-16.797,13.624-30.417,30.421-30.417h324.448c16.793,0,30.417,13.62,30.417,30.417V245.83z"
              />
            </svg>
          </div>
          <div className="access-message">
            Для получения доступа к этому функционалу обновите тарифный план
          </div>
        </div>
      )}
    </div>
  );
};

export default DevicesGp;
